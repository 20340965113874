import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"
import { ItemLinkTile } from "../../../molecules/ItemLinkTile"

import * as S from "./style"

const CreatorsPageContent: React.FC = () => {
  return (
    <S.Main>
      <S.Wrapper>
        <S.Header>
          <FormattedMessage
            defaultMessage="App / Useful materials for guides, tutorials or courses creators"
            description="information"
          />
        </S.Header>
        {/* <S.Description>
          <FormattedMessage
            defaultMessage="When creating learning content on your website, you would like to exactly know what techniques or solutions to apply. For this reason we created a list which might be useful for you."
            description="information"
          />
        </S.Description> */}
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="Preparing learning content"
            description="information"
          />
        </S.Subheader>
        <S.List>
          <S.ItemList>
            <ItemLinkTile
              name="Introduction to Learning Engeenering"
              description="The Learning Agency"
              link="https://www.the-learning-agency.com/learning-engineering-resources/introduction-to-learning-engineering/"
              linkType="external"
            />
          </S.ItemList>
          <S.ItemList>
            <ItemLinkTile
              name="Learn Better: Discover The Science of Learning"
              description="Ulrich Boser"
              link="https://www.amazon.com/Learn-Better-Mastering-Business-Anything/dp/1623365260"
              linkType="external"
            />
          </S.ItemList>
        </S.List>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="Building learning websites"
            description="information"
          />
        </S.Subheader>
        <S.List>
          <S.ItemList>
            <ItemLinkTile
              name="Build learning website on Next.js template"
              description="Vercel, Supabase, LearnTray"
              link="https://github.com/learntray/nextjs-learning-website"
              linkType="external"
            />
          </S.ItemList>
          <S.ItemList>
            <ItemLinkTile
              name="An easier, more powerful LMS"
              description="Eduflow"
              link="https://www.eduflow.com/"
              linkType="external"
            />
          </S.ItemList>
          <S.ItemList>
            <ItemLinkTile
              name="Turn Notion docs into interactive course"
              description="Aurora"
              link="https://www.aurora.courses/"
              linkType="external"
            />
          </S.ItemList>
          <S.ItemList>
            <ItemLinkTile
              name="Use web browser APIs"
              description="Mozilla Foundation"
              link="https://developer.mozilla.org/en-US/docs/Web/API"
              linkType="external"
            />
          </S.ItemList>
        </S.List>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="Exposing learning websites on the web"
            description="information"
          />
        </S.Subheader>
        <S.List>
          <S.ItemList>
            <ItemLinkTile
              name="Make website present in Google Search"
              description="Google"
              link="https://developers.google.com/search"
              linkType="external"
            />
          </S.ItemList>
          <S.ItemList>
            <ItemLinkTile
              name="Make courses present in users learning plans with Learn API (API proposal)"
              description="LearnTray"
              link="https://github.com/learntray/learn-api"
              linkType="external"
            />
          </S.ItemList>
        </S.List>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="Raising funds for learning software development"
            description="information"
          />
        </S.Subheader>
        <S.List>
          <S.ItemList>
            <ItemLinkTile
              name="Learning Engeenering Tools Competition"
              description="Schmidt Futures, Citadel, Bill & Melinda Gates Foundation, Walton Family Foundation, The Learning Agency"
              link="https://toolscompetition.org/"
              linkType="external"
            />
          </S.ItemList>
        </S.List>
        {/* <S.ItemList>
          <ItemLinkTile
            name="Big educational platforms vs. small educational websites and why small will matter much more in the future?"
            description="LearnTray"
            link="/blog/big-educational-platforms-vs-small-educational-websites"
            linkType="internal"
          />
        </S.ItemList>
        <S.ItemList>
          <ItemLinkTile
            name="Delivering your educational content to the world efficiently"
            description="LearnTray"
            link="/blog/delivering-your-educational-content-to-the-world"
            linkType="internal"
          />
        </S.ItemList> */}
        <S.Divider />
        <S.Header>
          <FormattedMessage
            defaultMessage="More information"
            description="information"
          />
        </S.Header>
        <S.Description>
          <FormattedMessage
            defaultMessage="Send message at: {email}"
            description="information"
            values={{
              email: <b>contact@learntray.com</b>,
            }}
          />
        </S.Description>
        {/* <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="Our blog posts"
            description="information"
          />
        </S.Subheader>
        <S.ItemList>
          <ItemLinkTile name="Blog Post 1" link="" linkType="internal" />
          <ItemLinkTile name="Blog Post 2" link="" linkType="internal" />
        </S.ItemList> */}
      </S.Wrapper>
    </S.Main>
  )
}

export { CreatorsPageContent }
