import { useIntl } from "gatsby-plugin-react-intl"
import React, { useState } from "react"
import { SEO } from "../components/atoms/SEO"
import { BaseDrawer } from "../components/organisms/BaseDrawer"
import { CreatorsPage } from "../components/sets/home/CreatorsPage"

const Home: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <SEO
        subtitle={intl.formatMessage({
          defaultMessage: "Creators",
          description: "website subtitle",
        })}
      />
      <CreatorsPage />
    </>
  )
}

export default Home
